<style lang="scss">
@import "../styles/main-view.scss";
</style>
<template>
  <div class="main-view">
    <div class="main-header">
      <!-- <img src="../../public/idea.png" style="width: 50px" /> -->
      <div class="header-content">
        <div class="card control">
          <v-select
            class="lession-list"
            :items="lessionList"
            label="Select lession"
            outlined
            v-model="selectedLessionName"
            height="40"
          ></v-select>
          <v-select
            class="language-list"
            :items="languageList"
            label="Select language"
            outlined
            v-model="selectedlanguage"
            height="40"
          ></v-select>
          <div class="start-btn" @click="onStart">
            <v-btn class="ma-2" color="info"> START </v-btn>
          </div>
        </div>
        <div class="sumup-info">
          <!-- <div>
            Lession: <span class="lession-name">{{ lessionName }}</span>
          </div>
          <div>
            Language: <span class="">{{ languageName }}</span>
          </div> -->
          <div>
            Progress:
            <span class="progress"
              ><span class="number-checked-words">{{ numberCheckedWords }}</span
              >/<span class="total-words">{{ totalWords }}</span></span
            >
            words
          </div>
        </div>
      </div>
    </div>
    <div class="main-content" v-if="isContentValid">
      <div class="back-container" @click="onBack">
        <v-icon x-large :style="{ visibility: canBack ? 'visible' : 'hidden' }">
          mdi-arrow-left-circle
        </v-icon>
      </div>
      <div class="word-container">
        <div class="word">{{ currentWord }}</div>
        <div class="meaning">
          <v-icon @click="toggleShowMeaning">
            {{ showMeaning ? "mdi-eye-outline" : "mdi-eye-off-outline" }}
          </v-icon>
        </div>
        <div class="explain" v-if="showMeaning">
          <div class="pronunciation">{{ currentPronunciation }}</div>
          <div class="example-container">
            <MeaningSentence :items="examples" />
          </div>
        </div>
      </div>
      <div class="next-container" @click="onNext">
        <v-icon x-large :style="{ visibility: canNext ? 'visible' : 'hidden' }">
          mdi-arrow-right-circle
        </v-icon>
      </div>
    </div>
    <div class="main-footer" v-if="isContentValid">
      <div class="back2-container" @click="onBack"></div>
      <div class="next2-container" @click="onNext"></div>
    </div>
  </div>
</template>

<script>
import { shuffle, get, isEmpty, concat } from "lodash";
import MeaningSentence from "./meaning-sentence.vue";
import Resources from "../data/resources.json";

const SUPPORTED_LANGUAGES = {
  ENGLISH: "English",
  VIETNAMESE: "Vietnamese",
};

export default {
  name: "MainView",
  components: {
    MeaningSentence,
  },
  data() {
    return {
      currentWordIndex: -1,
      lession: null,
      selectedLessionName: get(Resources, "[0].name"),
      selectedlanguage: SUPPORTED_LANGUAGES.ENGLISH,
      showMeaning: false,
      randomExample: null, // use in Vietnamese
    };
  },
  computed: {
    lessionList() {
      return Resources.filter((data) => data.contents.length > 0).map(
        (data) => data.name
      );
    },
    languageList() {
      return Object.values(SUPPORTED_LANGUAGES);
    },
    examples() {
      if (this.currentWordIndex < 0 || !this.lession) return [];
      return this.lession.contents[this.currentWordIndex].examples;
    },
    lessionName() {
      return this.lession ? this.lession.name : "_";
    },
    languageName() {
      return this.lession ? this.selectedlanguage : "_";
    },
    numberCheckedWords() {
      if (!this.lession) return "_";
      return this.currentWordIndex + 1;
    },
    totalWords() {
      if (!this.lession) return "_";
      return this.lession.contents.length;
    },
    currentWord() {
      if (!this.lession) return "";
      return this.lession.contents[this.currentWordIndex].word;
    },
    currentPronunciation() {
      if (!this.lession) return "";
      return this.lession.contents[this.currentWordIndex].pronunciation;
    },
    isContentValid() {
      return this.currentWordIndex >= 0;
    },
    canBack() {
      return this.lession && this.currentWordIndex > 0;
    },
    canNext() {
      return (
        this.lession && this.currentWordIndex < this.lession.contents.length - 1
      );
    },
  },
  methods: {
    // eslint-disable-next-line complexity
    onStart() {
      if (!this.selectedLessionName) {
        this.reset();
        return;
      }

      let tmp = Resources.filter(
        (res) => res.name === this.selectedLessionName
      )[0];
      if (!tmp) {
        this.reset();
        return;
      }

      const lession = JSON.parse(JSON.stringify(tmp));
      //lession.contents = shuffle(lession.contents);
      shuffle([]);
      this.lession = { name: lession.name, contents: [] };
      for (var i = 0; i < lession.contents.length; ++i) {
        const content = JSON.parse(JSON.stringify(lession.contents[i]));
        if (this.selectedlanguage === SUPPORTED_LANGUAGES.VIETNAMESE) {
          const wordInEnglish = content.word;
          for (var index2 = 0; index2 < content.examples.length; ++index2) {
            let value = {};
            const example = content.examples[index2];
            value.word = example.meaning;
            value.pronunciation = content.pronunciation;
            example.meaning = wordInEnglish;
            value.examples = [example];
            if (!isEmpty(value.word)) {
              this.lession.contents.push(value);
            }
          }
        } else {
          this.lession.contents.push(content);
        }
      }
      //combine all examples with same word
      if (this.lession.contents.length > 0) {
        const newContents = {};
        for (var index = 0; index < this.lession.contents.length; ++index) {
          const content = this.lession.contents[index];
          if (!newContents[content.word]) {
            newContents[content.word] = content;
          } else {
            newContents[
              content.word
            ].pronunciation += ` ${content.pronunciation}`;
            newContents[content.word].examples = concat(
              newContents[content.word].examples,
              content.examples
            );
          }
        }
        this.lession.contents = [];
        for (const [, value] of Object.entries(newContents)) {
          this.lession.contents.push(value);
        }
      }

      this.currentWordIndex = this.lession.contents.length > 0 ? 0 : -1;
      this.showMeaning = false;
    },
    reset() {
      this.currentWordIndex = -1;
      this.lession = null;
    },
    onBack() {
      if (this.currentWordIndex > 0) {
        this.currentWordIndex -= 1;
        //this.showMeaning = false;
      }
    },
    onNext() {
      if (this.currentWordIndex < this.lession.contents.length - 1) {
        this.currentWordIndex += 1;
        //this.showMeaning = false;
      }
    },
    toggleShowMeaning() {
      this.showMeaning = !this.showMeaning;
    },
  },
};
</script>
