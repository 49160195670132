<template>
  <div class="meaning-sentence">
    <div v-for="(item, index) in items" v-bind:key="index">
      <div style="color: #2196f3" v-if="item.meaning">
        {{ index + 1 + ". " + item.meaning }}
      </div>
      <ul style="list-style-type: none" v-if="item.meaning">
        <li v-for="(sentence, idx) in item.sentences" v-bind:key="idx">
          <span v-if="sentence">&bull; {{ sentence }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "meaning-sentence",
  props: {
    items: Array,
  },
};
</script>
